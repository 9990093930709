<template>
  <!-- 行业活动 -->
  <div class="Industry">
    <div style="width:100%;"
         class=" flex flexWrap">
      <div class="rowBox cursorP"
           @click="goother({path:`/meetingDetail?meetingId=${val.id}`})"
           :class="isHome == 1 ? 'row_box2':'row_box'"
           v-for="(val,i) in list"
           :key='i'>
        <img :src="$global.regexImg(val.logo_web)"
             alt=""
             class="minimg">
        <div class="rowBoxContent c33 fontW moreHidden marginAuto"
             style="height:53px;">
          {{val.meet_name}}
        </div>
        <div class="rowBoxTime marginAuto flex alignCenter"
             style="width:90%;height: 30px;">
          <img style="width: 12px;height: 12px;margin-right: 5px;"
               src="../assets/img/Industry/startTime.png"
               alt=""> {{val.start_date}}-{{val.end_date}}
        </div>
        <div class="rowBoxTime marginAuto flex alignCenter c66"
             style="width:90%;height: 30px;margin-top: 0;">
          <img style="width: 10px;height: 12px;margin-right: 7px;"
               src="../assets/img/Industry/location.png"
               alt=""> {{val.address}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Industry',
  props: {
    isHome: {
      type: [String, Number],
      default: 0
    },
    list: {
      type: Array,
      default: []
    }
  }
}
</script>

<style lang="scss" scoped>
.Industry {
  width: 100%;
}
.HomeBigCourse {
  width: 290px;
}
.rowBoxMin {
  width: 216px !important;
  min-height: 300px !important;
  border: 1px solid #cecece;
  background-color: pink;
  .minimg {
    width: 100%;
    height: 121.5px;
  }
  .rowBoxContent {
    font-size: 15px !important;
    height: 44px !important;
    line-height: 20px;
    width: 188px !important;
  }
  .rowBoxTeacher {
    width: 188px !important;
    .rowBoxTeacherList {
      width: 49%;
      margin-top: 0px;
      img {
        margin-right: 5px !important;
      }
    }
  }
}
.bgf {
  background: #ffffff;
}
.rowBox {
  background-color: #fff;
  margin-top: 23px;
  &:hover {
    transform: translateY(-10px);
    transition: all 0.3s;
  }
}
.row_box {
  margin-right: 17px;
  &:last-child {
    margin-right: 0px;
  }
}
.row_box2 {
  margin-right: 7px;
  &:last-child {
    margin-right: 0px;
  }
}
</style>
